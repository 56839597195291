import {getCookie} from "../../methods/cookie";
import {downloadCSV, fetchWrapper} from "../../methods/connector";
import {logout} from "../../methods/helpers";
import React, {useEffect, useState} from "react";
import {RunQuality, UserQuality} from "../../interfaces/AppConfig";
import {Background} from "../../components/background/Background";
import {HomeButton, HomeButtonRow} from "../../components/HomeButton/HomeButton";
import {inspect} from "node:util";
import styles from "./qualityView.module.scss";
import stylesGeneric from "../../styles/generics.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";


export const QualityView = () => {
  const jwt = getCookie('jwt');
  const [runQuality, setRunQuality] = useState<RunQuality[]>([]);
  const [userQuality, setUserQuality] = useState<UserQuality[]>([]);

  const downloadData = () => {
    const resRunCSV: [string, string, string, string][] = [];
    const resUserCSV: [string, string, string, string, string][] = [];

    resRunCSV.push(['mean', 'std', 'team', 'timestamp']);
    resUserCSV.push(['user', 'mean', 'std', 'team', 'timestamp']);

    runQuality.forEach((entry) => {
      resRunCSV.push([entry.mean.toString(), entry.std.toString(), entry.team, entry.timestamp.toString()]);
    })

    userQuality.forEach((entry) => {
      resUserCSV.push([entry.user_id, entry.mean.toString(), entry.std.toString(), entry.team, entry.timestamp.toString()]);
    })

    if (resRunCSV.length > 0) {
      downloadCSV(resRunCSV, 'runQuality.csv')
    }

    if (resUserCSV.length > 0) {
      downloadCSV(resUserCSV, 'userQuality.csv')
    }
  }

  useEffect(() => {
    const getRes = async () => {
      let url = `${process.env.REACT_APP_URL}/calibrationQuality`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: {runs: RunQuality[], users: UserQuality[]} = await data.json();
      setRunQuality(r.runs);
      setUserQuality(r.users);
    }

    getRes();
  }, [])

  return <div>
    <Background/>
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
          downloadData();
        }}>
          <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faDownload}/>
        </button>
      </div>
    </div>
    <div className={styles.columnWrapper}>
      <div className={styles.column}>
        <div className={styles.columnHeader}>
          <p className={styles.columnHeaderCaption}>
            Calibration runs
          </p>
        </div>
        <div className={styles.cells}>
          {
            runQuality.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1).map((run: RunQuality, index: number) =>
              <div className={styles.cell} key={index}>
                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Mean:</p>
                  <p className={styles.cellTextContent}>{run.mean}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>STD:</p>
                  <p className={styles.cellTextContent}>{run.std}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Team:</p>
                  <p className={styles.cellTextContent}>{run.team}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Date:</p>
                  <p className={styles.cellTextContent}>{(new Date(run.timestamp)).toDateString()}</p>
                </div>
              </div>
            )
          }

        </div>
      </div>

      <div className={styles.column}>
        <div className={styles.columnHeader}>
          <p className={styles.columnHeaderCaption}>
            User performance
          </p>
        </div>
        <div className={styles.cells}>
          {
            userQuality.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1).map((run: UserQuality, index: number) =>
              <div className={styles.cell} key={index}>
                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>User name:</p>
                  <p className={styles.cellTextContent}>{run.user_id}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Mean:</p>
                  <p className={styles.cellTextContent}>{run.mean}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>STD:</p>
                  <p className={styles.cellTextContent}>{run.std}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Team:</p>
                  <p className={styles.cellTextContent}>{run.team}</p>
                </div>

                <div className={styles.cellRow}>
                  <p className={styles.cellTextCaption}>Date:</p>
                  <p className={styles.cellTextContent}>{(new Date(run.timestamp)).toDateString()}</p>
                </div>
              </div>
            )
          }

        </div>
      </div>
    </div>
  </div>

}
