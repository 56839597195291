import React, { useEffect, useState } from "react";
import styles from "./edit.module.scss";
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfigs} from "../../interfaces/AppConfig";
import {fetchWrapper, postDesign} from "../../methods/connector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {Background} from "../../components/background/Background";
import {logout} from "../../methods/helpers";
import "react-datepicker/dist/react-datepicker.css";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {getLanguageCodes} from "../../methods/getEvents";

const _ = require('lodash');

const languageCodes = getLanguageCodes()

// list all stages for the given config, with a button to open and edit them
export const LanguageEditor = () => {
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const language = routeParams.language;

  const navigate = useNavigate();
  const jwt = getCookie('jwt');

  const [design, setDesign] = useState<AppConfigs>();
  const [add, setAdd] = useState<boolean>(false);
  const [addString, setAddString] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const addEntry = async () => {
    if (!design || !designTag || !language) {
      return;
    }
    const d = _.cloneDeep(design)

    if (d?.language?.[language]?.[addString]) {
      return;
    }

    if (!(d?.language)){
      d.language = {};
    }
    if (!(d.language[language])){
      d.language[language] = {};
    }
    d.language[language][addString] = "";
    setDesign(d);
  }

  const deleteEntry = async (key: string) => {
    if (!design || !designTag || !language) {
      return;
    }
    const d = _.cloneDeep(design)
    delete d?.language?.[language]?.[key];
    setDesign(d);
  }

  const setValue = (key: string, value: string) => {
    if (!design || !designTag || !language) {
      return;
    }

    const d = _.cloneDeep(design)
    if (d?.language?.[language]?.[key] === undefined) {
      return;
    }
    d.language[language][key] = value;
    setDesign(d);

  }

  const postConfig = async () => {
    if (!design) {
      return;
    }

    setLoading(true);
    const data = await postDesign(design, jwt)
    setLoading(false);
    if (data.status !== 200) {
      return;
    }
  }

  const getDesign = async() => {
    let url = `${process.env.REACT_APP_URL}/designTag/${designTag}`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: AppConfigs = await data.json();
    setDesign(r);
  }

  useEffect(() => {
    getDesign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={stylesGeneric["page"]}>
    <Background/>
    {/*<HomeButtonRow/>*/}
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <SaveButton onClick={postConfig}/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
          setAdd(!add);
        }}>
          <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
        </button>
      </div>
    </div>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <table className={styles['table']}>
          <thead className={styles['thead']}>
          <tr className={styles['tr-head']}>
            <td className={styles['td-head-tag']}/>
            <td className={styles['td-head-value']}>
              {`${designTag} | ${languageCodes[language ?? ""] ?? language}`}
            </td>
            <td className={styles['td-head']}/>
          </tr>
          </thead>
          <tbody className={styles['tbody']}>
          {
            Object.entries(design?.language?.[language ?? ""] ?? {}).sort().map(([key, value]) => {
              return <tr className={styles['tr']} key={key}>
                <td className={styles['td-tag']}>
                  {key}
                </td>
                <td className={styles['td-value']}>
                  <input
                    className={styles["value-field"]}
                    value={value}
                    onChange={(e) => {setValue(key, e.target.value)}}
                  >

                  </input>
                </td>
                <td className={styles['td-button']}>
                  <input className={styles["button"]} value={"Delete"} onClick={() => deleteEntry(key)} type="button"/>
                </td>
              </tr>
            })
          }
          </tbody>
        </table>
      </div>
    </div>
    {
      add
        ?
        <div className={styles["add-wrapper"]}>
          <input value={addString} placeholder={'Enter new key...'} onChange={(e) => {setAddString(e.target.value)}} className={styles["add-input"]}/>
          <div className={styles["add-buttons"]}>
            <input className={styles["add-button"]} value={"OK"} onClick={addEntry} type="button"/>
            <input className={styles["add-button"]} value={"Close"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
          </div>
        </div>
        : null
    }
    <LoadingOverlay loading={loading}/>
  </div>
}
