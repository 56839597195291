import {AppConfig, AppConfigs, AppDesign} from "../interfaces/AppConfig";

export const retrieveKey = (obj: any, key: string) => {
  let res = obj;
  const keySplit = key.split('.');
  for (const k of keySplit) {
    res = res[k];
    if (res === undefined || res === null) {
      return "";
    }
  }
  return res;
}

export const getDeepKeys = (obj: Record<any, any>) => {
  let keys: string[] = [];
  for(const key in obj) {
    keys.push(key);
    if(typeof obj[key] === "object") {
      const subkeys = getDeepKeys(obj[key]);
      keys = keys.concat(subkeys.map(function(subkey) {
        return key + "." + subkey;
      }));
    }
  }
  return keys;
}

export const mergeConfigs = (input: AppConfig, template: AppConfig) => {

  if (!input.settings.callFunction && template.settings.callFunction) {
    input.settings.callFunction = template.settings.callFunction;
    input.settings.eventTargets = template.settings.eventTargets;
    input.settings.copyParameters = template.settings.copyParameters;
    input.settings.setParameters = template.settings.setParameters;
    input.settings.postURL = template.settings.postURL;
    input.settings.pingURL = template.settings.pingURL;
    input.settings.getURL = template.settings.getURL;
    input.settings.getKey = template.settings.getKey;
    input.settings.getGroup = template.settings.getGroup;
    input.settings.resKey = template.settings.resKey;
    input.settings.resGroup = template.settings.resGroup;
    input.settings.codeType = template.settings.codeType;
    input.settings.codeEnableFlip = template.settings.codeEnableFlip;
    input.settings.codeFrontCamera = template.settings.codeFrontCamera;

  }

  if ((!input.settings.functionParameters || Object.keys(input?.settings?.functionParameters ?? {}).length === 0) && template.settings.functionParameters) {
    input.settings.functionParameters = template.settings.functionParameters;
  }

  if (input.settings.timeout?.timer === 0 && (template.settings.timeout?.timer ?? 0) > 0) {
    input.settings.timeout = template.settings.timeout;
  }

  if (!input.design.background && template.design.background) {
    input.design.background = template.design.background;
    input.design.backgroundBounds = template.design.backgroundBounds;
    input.design.backgroundSize = template.design.backgroundSize;
    input.design.backgroundType = template.design.backgroundType;
    input.design.absolute = template.design.absolute;
  }

  for (const [elName, el] of Object.entries(template.design.elements ?? {})) {
    if (!(input.design.elements[elName])){
      input.design.elements[elName] = el;
    }
  }
}


export const renameKey = (design: AppConfigs, oldName: string, newName: string) => {
  if (design.settings.initialStage === oldName) {
    design.settings.initialStage = newName;
  }

  if (design.settings.homeStage === oldName) {
    design.settings.homeStage = newName;
  }

  for (const [_pageName, page] of Object.entries(design.configs)) {
    for (const [_elementName, element] of Object.entries(page.design.elements ?? {})) {
      if (element.goTo === oldName) {
        element.goTo = newName;
      }
    }

    for (const [eventName, eventTarget] of Object.entries(page.settings.eventTargets ?? {})) {
      if (eventTarget === oldName && page.settings.eventTargets) {
        page.settings.eventTargets[eventName] = newName;
      }
    }

    if (page.settings.timeout?.onExpiration === oldName) {
      page.settings.timeout.onExpiration = newName;
    }
  }
}


export const getPermission = (key: string,
                              designs: AppConfigs,
                              design: AppConfig,
                              permissionDict: Record<string, {write?: boolean, read?: boolean}>,
                              userPermission: number = 4) => {
  if (permissionDict[key]) {
    return permissionDict[key];
  }
  const permissions = [design.permission ?? {}, designs.permission ?? {}];
  const returnObject = {read: true, write: true};
  for (const permission of permissions) {
    const keySplit = key.split('.');
    while (keySplit.length > 0) {
      let p = permission[keySplit.join('.')];
      if (!p) {
        p = permission[[...keySplit.slice(0, keySplit.length - 1), '*'].join('.')];
      }
      if (p) {
        returnObject.read = (p.read ?? 0) <= userPermission;
        returnObject.write = (p.write ?? 0) <= userPermission;
        permissionDict[key] = returnObject;
        // setPermissionDict(permissionDict);
        return returnObject;
      }
      keySplit.pop();
    }
  }
  return returnObject;
}

export const getKeyVal = (key: string, obj?: AppConfig, objFilled?: AppConfig, type = 'string'): [any, number] => {
  if (!obj) {
    return [undefined, -1];
  }
  let originLevel = 0;
  const keySplit = key.split('.');
  let val: any = obj;
  for (const k of keySplit) {
    val = val[k];
    if (!val) {
      break;
    }
  }
  if (((obj.settings.useTemplate && obj.settings.useTemplate !== "") || keySplit[1] === 'elements') && (val === undefined || val === null || (val === 0 && type === 'number') || (val === "" && type === 'string')) && objFilled !== undefined) {
    val = objFilled;
    for (const k of keySplit) {
      val = val[k];
      if (!val) {
        break;
      }
    }
    if (val !== undefined && val !== null && !(val === 0 && type === 'number') && !(val === "" && type === 'string')) {
      originLevel = 1;
    }
  }

  if (val === undefined) {
    originLevel = -1;
  }

  return [val, originLevel];
};


export const openTab = (e: React.MouseEvent<HTMLButtonElement | HTMLInputElement>, url: string) => {
  if (e.button === 0) {
    window.open(url, '_self')
  } else if (e.button === 1) {
    window.open(url, 'rel=noopener noreferrer')
  }
}

export const logout = () => {
  document.cookie = `jwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`
  window.alert("Session expired. Please log in again.");
  window.open('/auth', '_self')
}
