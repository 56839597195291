import React, {useEffect, useRef, useState} from "react";
import  styles from "./RenameField.module.scss";
import stylesConfig from "../../screens/configGenerator/config.module.scss"
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";

export const colorRed = "#8f0000";
export const colorBlue = "#00749e";


export const RenameField = (props: {
  path: string,
  onChange: ((val: string, elemName: string) => void)
  readOnly?: boolean,
  type?: string,
  style?: Record<string, string>,
  parentProps: {
    getPermissionWrapper: (e: string) => ({ read?: boolean; write?: boolean });
    design: AppConfig | undefined;
    designFilled: AppConfig | undefined;
    designs: AppConfigs | undefined;
    setter: (baseKey: string, val: any) => void;
    getPermissionType: (e: string, type: string) => (string);
    deleteKey: (path: string) => void;
    designTag: string;
    translate: ((e: string) => string);
    isInstance: boolean;
  }
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const currentValSplit = props.path.split('.');
  const currentVal = currentValSplit[currentValSplit.length - 1];
  let originLevel = 0;
  if (props.parentProps.design?.design.elements?.[currentVal] === undefined) {
    originLevel = 1;
  }

  const [renameVal, setRenameVal] = useState<string>("");

  // const [currentVal, originLevel] = getKeyVal(`${props.path}.elementName`, props.parentProps.design, props.parentProps.designFilled);

  let deleted = false;
  if (props.parentProps.design?.deletedKeys?.includes(props.path) && originLevel !== 0) {
    deleted = true;
  }

  let color = "#000000";
  switch (originLevel) {
    case 1: color = colorBlue; break;
  }
  if (deleted) {
    color = colorRed;
  }


  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)){
        setRenameVal("");
        return;
      }
    }    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") {
      return;
    }
    props.onChange(currentVal, renameVal);
    setRenameVal("");
  }

  return <div className={styles['wrapper']} ref={wrapperRef}>
    <input
      className={stylesConfig['value-input-string']}
      value={renameVal !== "" ? renameVal : currentVal}
      onChange={(e) => {
        setRenameVal(e.target.value)
      }}
      onKeyDown={handleChange}
      readOnly={props.readOnly ?? false}
      type={props.type ?? 'text'}
      style={{...props.style, color} ?? {color}}
    />
    {
      props.parentProps.isInstance
        ? <input
          type={"button"}
          style={{color: deleted ? "#0F0" : "#F00"}}
          value={deleted ? "✓" : "❌"}
          onClick={() => props.parentProps.deleteKey(props.path)}
        />
        : null
    }
  </div>
}
