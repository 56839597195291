import stylesGeneric from "../../styles/generics.module.scss";
import {Background} from "../../components/background/Background";
import {HomeButtonRow} from "../../components/HomeButton/HomeButton";
import styles from "./verifyUser.module.scss";
import React, {useEffect, useState} from "react";
import {logout} from "../../methods/helpers";
import {getCookie} from "../../methods/cookie";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {fetchWrapper} from "../../methods/connector";

export const VerifyUser = () => {
  const jwt = getCookie('jwt');

  const [users, setUsers] = useState<{
    username: string, email: string, fullname: string, usergroup: string, enabled: boolean
  }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getUsers = async() => {
    let url = `${process.env.REACT_APP_URL}/userListFull`;
    const data = await fetchWrapper(url, jwt, 'get');
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r = await data.json();
    // setUsers(r.sort());
    setUsers(r);
  }

  const editUser = async (username: string, value: Record<string, string | boolean>) => {
    let url = `${process.env.REACT_APP_URL}/modifyUser`;
    setLoading(true);
    const response = await fetchWrapper(url, jwt, 'post',
      JSON.stringify({username: username, ...value}));
    setLoading(false);
    if (response.status === 401) {
      logout();
      return;
    }

    const resp = await response.json();
    getUsers();
    return;

  };


  useEffect(() => {


    getUsers();

  }, []);

  return <div className={stylesGeneric["page"]}>
    <Background/>
    <HomeButtonRow/>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <table className={styles["verify-table"]}>
          <thead className={styles["verify-thead"]}>
            <tr>
              <td className={styles["verify-head-cell"]}>
                User
              </td>
              <td className={styles["verify-head-cell"]}>
                Full name
              </td>
              <td className={styles["verify-head-cell"]}>
                Group
              </td>
              <td className={styles["verify-head-cell"]}>
                E-Mail
              </td>
              <td className={styles["verify-head-cell"]}>
                Verified?
              </td>
            </tr>
          </thead>
          <tbody className={styles["verify-tbody"]}>
          {
            users.map((user) => {
              return <tr className={styles["verify-row"]} key={user.username}>
                <td className={styles["verify-body-cell"]}>
                  {user.username}
                </td>
                <td className={styles["verify-body-cell"]}>
                  <input
                    defaultValue={user.fullname}
                    onBlur={(e) => e.target.value = user.fullname}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        const target = e.target as HTMLTextAreaElement;
                        editUser(user.username, {fullname: target.value})
                      }
                    }}
                  />
                </td>
                <td className={styles["verify-body-cell"]}>
                  <input
                    defaultValue={user.usergroup}
                    onBlur={(e) => e.target.value = user.usergroup}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        const target = e.target as HTMLTextAreaElement;
                        editUser(user.username, {usergroup: target.value})
                      }
                    }}
                  />
                </td>
                <td className={styles["verify-body-cell"]}>
                  <input
                    defaultValue={user.email}
                    onBlur={(e) => e.target.value = user.email}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === 'Enter') {
                        const target = e.target as HTMLTextAreaElement;
                        editUser(user.username, {email: target.value})
                      }
                    }}
                  />
                </td>
                <td className={styles["verify-body-cell"]}>
                  <input
                    type={"checkbox"}
                    checked={user.enabled}
                    onChange={() => {editUser(user.username, {enabled: !user.enabled})}}
                  />
                </td>

              </tr>
            })
          }
          </tbody>
        </table>


      </div>
    </div>
    <LoadingOverlay loading={loading}/>
  </div>
}
