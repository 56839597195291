import React, {useEffect, useRef, useState} from "react";
import  styles from "./uploader.module.scss";
import stylesGeneric from "../../styles/generics.module.scss";
import {getCookie} from "../../methods/cookie";
import {AssignmentMedia, Media, MediaLink} from "../../interfaces/Media";
import {Background} from "../../components/background/Background";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {ThreeDots} from "react-loader-spinner";
import {logout} from "../../methods/helpers";
import {fetchWrapper, postDesign} from "../../methods/connector";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {getLanguageCodes} from "../../methods/getEvents";

const _ = require('lodash');

const icon = require('../../resources/images/file-manager-icon.png')

export const Uploader = () => {
  const jwt = getCookie('jwt');
  const [media, setMedia] = useState<Media[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [assignments, setAssignments] = useState<AssignmentMedia[]>([]);
  const [currentMedia, setCurrentMedia] = useState<string>("");
  const [currentEntry, setCurrentEntry] = useState<string>("");
  const [currentLocation, setCurrentLocation] = useState<string>("");
  const [searchName, setSearchName] = useState<string>();
  const [fileName, setFileName] = useState<string>("");
  const [currentAssignment, setCurrentAssignment] = useState<AssignmentMedia>();
  const [newPermission, setNewPermission] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [addString, setAddString] = useState<string>('');
  const [linker, setLinker] = useState<Media | undefined>(undefined);
  const [links, setLinks] = useState<MediaLink[]>([]);
  const [linkMedia, setLinkMedia] = useState<string>("");
  const inputFile = useRef<HTMLInputElement>(null);


  const openAssignment = (fileName: string) => {
    setCurrentEntry(fileName);
  }

  const copyUrl = (path: string) => {
    navigator.clipboard.writeText(path);
  }

  const getAssignments = async() => {
    let url = `${process.env.REACT_APP_URL}/media/assignments`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: AssignmentMedia[] = await data.json();
    setAssignments(r);

  }

  const getLinks = async() => {
    let url = `${process.env.REACT_APP_URL}/media/links`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: MediaLink[] = await data.json();
    setLinks(r);
  }

  const sendLinks = async(fileName: string, links: Record<string, string>) => {
    let url = `${process.env.REACT_APP_URL}/media/linkDir/${fileName}`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({links: links}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: MediaLink[] = await data.json();
    getLinks()
  }

  const createAssignment = async (assignment: AssignmentMedia) => {
    let url = `${process.env.REACT_APP_URL}/media/sendAssignmentMedia`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    setCurrentAssignment(undefined);
    setSearchName("");
    getAssignments();
  }

  const sendAssignment = async(value: number, assignment: AssignmentMedia) => {
    let url = `${process.env.REACT_APP_URL}/media/sendAssignmentMedia`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment, permission: value}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    setCurrentAssignment(undefined);
    getAssignments();
  }

  const deleteAssignment = async(assignment?: AssignmentMedia) => {
    if (!assignment) {return}
    let url = `${process.env.REACT_APP_URL}/media/deleteAssignmentMedia`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    setCurrentAssignment(undefined);
    getAssignments();
  }

  const addDir = async () => {
    setLoading(true);
    let url = `${process.env.REACT_APP_URL}/media/dir/${addString}`;
    const data = await(fetch(url, {
      method: "post",
      // body: form,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' + jwt,
      },
    }));
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    setAddString("");
    setAdd(false);
    setLoading(false);
    setFileName("");
    getList();
    getAssignments();
  }

  const deleteDir = async (dirName: string) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_URL}/media/dir/${dirName}`;
    const data = await(fetch(url, {
      method: "delete",
      // body: form,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' + jwt,
      },
    }));
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    setAdd(false);
    setLoading(false);
    getList();
    getAssignments();
  }

  const uploadMedia = async () => {
    const file = inputFile?.current?.files?.[0];
    if (!file || fileName === "") {
      return;
    }
    const form = new FormData();
    form.append('file', file);

    setLoading(true);
    let url = `${process.env.REACT_APP_URL}/media/${fileName}`;
    const data = await(fetch(url, {
      method: "post",
      body: form,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Accept': "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' + jwt,
      },
    }));
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    inputFile.current.value = "";
    setLoading(false);
    setFileName("");
    getList();
    getAssignments();
  }

  const distributeMedia = async(fileName: string, locations: string[], server: string, exists: boolean) => {
    if (locations.length === 1 && locations[0] === server ) {
      if (!(window.confirm(`This will delete the image entirely. Continue?`))){
        return;
      }
    }
    let url = `${process.env.REACT_APP_URL}/media/store/${fileName}?server=${server}`;
    const method = exists ? 'delete' : 'post';
    setLoading(true);
    const data = await fetchWrapper(url, jwt, method);
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const m = await data.json();
    setMedia(m);

  }

  const getList = async () => {
    let url = `${process.env.REACT_APP_URL}/media`;
    const data = await fetchWrapper(url, jwt, 'get');
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const m = await data.json();
    setMedia(m);
  }


  useEffect(() => {
    const getUsers = async() => {
      let url = `${process.env.REACT_APP_URL}/userList`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: string[] = await data.json();
      setUsers(r.sort());
    }


    getList();
    getAssignments();
    getUsers();
    getLinks();

    const handleClickOutside = (event: MouseEvent) => {
      // if a click occurs that is not targeting a Dropdown-component, unselect the current dropdown target
      const t = event.target as HTMLDivElement;
      if (typeof t.className !== 'string'){
        return;
      }

      let a: ParentNode | null = t;
      const elements: any[] = [];
      while (a) {
        elements.unshift(a);
        a = a.parentNode;
      }
      const elementNames = elements.map((element) => element.className).filter(e => !!e);

      if (elementNames.filter(e => e.includes('assignment-row-permission')).length < 1) {
        setCurrentAssignment(undefined);
      }
      if (elementNames.filter(e => e.includes('location-wrapper')).length < 1) {
        setCurrentLocation("");
      }
      if (elementNames.filter(e => e.includes('assignment')).length < 1) {
        setCurrentEntry("");
      }
      if (elementNames.filter(e => e.includes('assignment-new')).length < 1) {
        setSearchName("")
      }
      if (elementNames.filter(e => e.includes('image-wrapper')).length < 1) {
        setCurrentMedia("")
      }
      if (elementNames.filter(e => e.includes('link-select')).length < 1) {
        setLinkMedia("")
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={stylesGeneric["page"]}>
      <Background/>
      <div className={stylesGeneric["settings-button-row"]}>
        <div className={stylesGeneric["settings-button"]}>
          <HomeButton/>
        </div>
        <div className={stylesGeneric["settings-button"]}>
          <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
            setAdd(!add);
          }}>
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
          </button>
        </div>
      </div>
      <div className={stylesGeneric["content-wrapper"]}>
        <div className={stylesGeneric["page-wrapper"]}>
          <table>
            <tbody>
            {
              media.map((m) => {
                const defaultUrl = `https://${m.locations[0]?.location ?? ''}/${m.fileName}`;
                if (m.type === 'dir') {
                  return <tr
                    className={stylesGeneric["table-row"]}
                    key={m.fileName}
                  >
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrower']}`}>
                      <img
                        className={styles['image-preview']}
                        src={icon}
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                      {m.fileNameClear}
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                      <input
                        type="button"
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          setLinker(m)
                        }}
                        value="Link to images"
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type="button"
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          copyUrl(m.fileName)
                        }}
                        value="Copy URL"
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type="button"
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          openAssignment(m.fileName)
                        }}
                        value="Assign"
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type={'button'}
                        value={'Delete'}
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteDir(m.fileName)
                        }}
                      />
                    </td>
                  </tr>
                } else {
                  return <tr
                    className={stylesGeneric["table-row"]}
                    key={m.fileName}
                  >
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrower']}`}/>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                      <img
                        className={styles['image-preview']}
                        onClick={() => {
                          setCurrentMedia(defaultUrl)
                        }}
                        src={defaultUrl}
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                      {m.fileNameClear}
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type="button"
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          copyUrl(m.fileName)
                        }}
                        value="Copy URL"
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type="button"
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          openAssignment(m.fileName)
                        }}
                        value="Assign"
                      />
                    </td>
                    <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                      <input
                        type={'button'}
                        value={'Locations'}
                        className={styles["table-button"]}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentLocation(m.fileName)
                        }}
                      />
                    </td>
                  </tr>
                }
              })
              }
            <tr
              className={stylesGeneric["table-row"]}
            >
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrower']}`}/>
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                <input type={"file"} id={'file'} ref={inputFile} className={styles['inputButton']}/>
              </td>
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-wide']}`}>
                <input
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value)
                  }}
                  placeholder={'New file name...'}
                  className={styles['inputButton']}
                />
              </td>
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}>
                <input
                  type={'button'}
                  className={styles["table-button"]}
                  value={'Upload'}
                  onClick={uploadMedia}
                />
              </td>
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}/>
              <td className={`${stylesGeneric["table-cell"]} ${styles['table-cell-narrow']}`}/>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      {
        currentLocation !== ""
          ?
          <div className={styles["location-wrapper"]}>
            <table className={styles["location-table"]}>
              <thead className={styles["location-thead"]}>
                <tr>
                  <td className={styles['location-cell-name']}>
                    Location of {currentLocation}
                  </td>
                  <td className={styles['location-cell-checkbox']}>
                    Exists?
                  </td>
                </tr>
              </thead>
              <tbody className={styles["location-tbody"]}>
              {
                (process.env.REACT_APP_MEDIA_URLS ?? '').replace(/ /g, '').split(',').map((l) => {
                  const m = media.filter((m) => m.fileName === currentLocation)[0];
                  if (!m) {
                    setCurrentLocation('');
                    return null;
                  }
                  const exists = m.locations.map((loc) => loc.apiUrl).includes(l);
                  return <tr
                    className={styles['location-row']}
                    key={l}>
                    <td className={styles['location-cell-name']}>
                      {l}
                    </td>
                    <td className={styles['location-cell-checkbox']}>
                      <input
                        className={styles['location-checkbox']}
                        readOnly={true}
                        type={'checkbox'}
                        checked={exists}
                        onClick={() => {
                          distributeMedia(
                            m.fileName,
                            m.locations.map((e) => e.apiUrl),
                            l,
                            exists
                          )
                        }
                      }
                      />
                    </td>
                  </tr>
                })

              }
              </tbody>
            </table>
            <div className={styles['assignment-button-row']}>
              <input
                type={'button'}
                className={styles['assignment-button-row-close']}
                value={"CLOSE"}
                onMouseDown={(e) => {
                  setCurrentLocation("");
                }}
              >
              </input>
            </div>
          </div>
          : null
      }
      {
        currentEntry !== ""
          ?
          <div className={styles["assignment-wrapper"]}>
            <table className={styles["assignment-table"]}>
              <thead className={styles["assignment-thead"]}>
              <tr>
                <td className={`${styles["assignment-head-user"]} ${styles['assignment-cell-inactive']}`}>
                  User
                </td>
                <td className={`${styles["assignment-head-permission"]} ${styles['assignment-cell-inactive']}`}>
                  Permission
                </td>
                <td className={`${styles["assignment-head-delete"]} ${styles['assignment-cell-inactive']}`}>

                </td>

              </tr>
              </thead>
              <tbody className={styles["assignment-tbody"]}>
              {
                assignments.filter(el => el.fileName === currentEntry).map(el => {
                  return <tr className={styles["assignment-row"]} key={`${el.fileName}${el.username}`}>
                    <td className={styles["assignment-row-user"]}>
                      {el.username}
                    </td>
                    <td className={styles["assignment-row-permission"]}>
                      <input
                        type={"number"}
                        value={currentAssignment?.username === el.username ? newPermission : el.permission}
                        onFocus={() => {setNewPermission(el.permission); setCurrentAssignment(el)}}
                        onChange={(e) =>
                          setNewPermission(Math.max(0, Math.min(999, Number(e.target.value))))
                        }
                        onKeyDown={ async (e) => {
                          if (e.key !== 'Enter' || !currentAssignment) {
                            return;
                          }
                          await sendAssignment(newPermission, currentAssignment);
                        }}
                      />
                    </td>
                    <td className={styles["assignment-row-delete"]} onClick={() => {deleteAssignment(el)}}>
                      X
                    </td>
                  </tr>
                })
              }
              <tr className={styles['assignment-row']}>
                <td className={styles["assignment-row-user"]}>
                  <input className={styles["assignment-new"]} placeholder="..." value={searchName} onChange={(e) => {setSearchName(e.target.value)}}>
                  </input>
                  {
                    (searchName !== "" && searchName !== undefined)
                      ?
                      <div className={styles["assignment-new-wrapper-outer"]}>
                        <div className={styles["assignment-new-wrapper"]}>
                          {users.filter(el => el.toLowerCase().includes(searchName.toLowerCase() || "")).map(el => {
                            return <div key={el} className={styles["assignment-new-option"]} onClick={() => {createAssignment({username: el, fileName: currentEntry, permission: 1})}}>
                              {el}
                            </div>
                          })}
                        </div>
                      </div>
                      : null
                  }
                </td>
                <td className={styles['assignment-cell-inactive']}/>
                <td className={styles['assignment-cell-inactive']}/>
              </tr>
              </tbody>
            </table>
            <div className={styles['assignment-button-row']}>
              <input
                type={'button'}
                className={styles['assignment-button-row-close']}
                value={"CLOSE"}
                onMouseDown={(e) => {
                  setCurrentAssignment(undefined);
                  setCurrentEntry("");
                  setSearchName("")
                }}
              >
              </input>
            </div>
          </div>
          : null
      }
      {
        currentMedia !== ""
          ?
            <div
              className={styles['image-wrapper']}
            >
              <img
                className={styles['image']}
                src={currentMedia}
              />
              <div className={styles['assignment-button-row']}>
                <input
                  type={'button'}
                  className={styles['assignment-button-row-close']}
                  value={"CLOSE"}
                  onMouseDown={(e) => {
                    setCurrentMedia("");
                  }}
                >
                </input>
              </div>
            </div>

          : null
      }
      {
        linker
          ?
          <div
            className={styles['link-wrapper']}
          >
            <div className={styles['link-wrapper-inner']}>
              <table className={styles['link-table']}>
                <thead className={styles['link-thead']}>
                <tr className={styles['link-tr-head']}>
                  <td className={styles['link-td-head']}>
                    Language
                  </td>
                  <td className={styles['link-td-head']}>
                    Image
                  </td>
                  <td/>
                </tr>
                </thead>
                <tbody className={styles['link-tbody']}>
                {
                  Object.entries(links.filter((link) => link.fileName === linker.fileName)?.[0]?.links ?? {}).map(([key, l]) => {
                  const img = media.filter((m) => m.fileName === l)[0];
                  let url = "";
                  if (img) {
                    url = `https://${img.locations[0]?.location ?? ''}/${l}`;
                  }
                  return <tr className={styles['link-tr-body']} key={`link-row-${key}`}>
                    <td className={styles['link-td-body']}>
                      {key}
                    </td>
                    <td className={styles['link-td-body']}>
                      <img
                        className={styles['link-image']}
                        onClick={() => {
                          setLinkMedia(key)
                        }}
                        src={url}
                      />
                    </td>
                    <td className={styles['link-td-body']}>
                      <input className={styles['assignment-button-row-close']} type={'button'} value={'Delete'} onClick={(el) => {
                        const linksClone: MediaLink[] = _.cloneDeep(links);
                        let link = linksClone.filter((link) => link.fileName === linker.fileName)?.[0];
                        if (!link) {
                          link = {fileName: linker?.fileName, links: {}};
                        }
                        if (!(link.links)) {
                          link.links = {};
                        }
                        delete link.links[key];
                        setLinks(linksClone);
                      }}/>
                    </td>
                  </tr>
                })}
                <tr className={styles['link-tr-body']}>
                  <td className={styles['link-td-body']}>
                    <Dropdown
                      currentVal={"Enter new key..."}
                      valueList={Object.keys(getLanguageCodes())}
                      allowOther={true}
                      requiresEnter={true}
                      onChange={(e) => {
                        const linksClone: MediaLink[] = _.cloneDeep(links);
                        let link = linksClone.filter((link) => link.fileName === linker.fileName)?.[0];
                        if (!link) {
                          link = {fileName: linker?.fileName, links: {}};
                          linksClone.push(link);
                        }
                        if (!(link.links)) {
                          link.links = {};
                        }
                        if (link.links[e]) {
                          return;
                        }
                        link.links[e] = "";
                        setLinks(linksClone);
                    }}/>
                  </td>
                </tr>

                </tbody>
              </table>
            </div>
            <div className={styles['link-button-row']}>
              <input
                type={'button'}
                className={styles['link-button']}
                value={"SAVE"}
                onMouseDown={async (e) => {
                  const linksClone: MediaLink[] = _.cloneDeep(links);
                  let link = linksClone.filter((link) => link.fileName === linker.fileName)?.[0] ?? {links: {}};
                  await sendLinks(linker?.fileName, link.links)
                  setLinker(undefined);
                }}
              >
              </input>
              <input
                type={'button'}
                className={styles['link-button']}
                value={"CANCEL"}
                onMouseDown={(e) => {
                  getLinks();
                  setLinker(undefined);
                }}
              >
              </input>
            </div>
            {
              linkMedia !== ""
              ?
                <div className={styles['link-select-wrapper']}>
                  <div className={styles['link-select-wrapper-inner']}>
                    <div className={styles['link-select-table']}>
                      {
                        media.filter((m) => m.type !== 'dir').map((el) => {
                          const url = `https://${el.locations[0]?.location ?? ''}/${el.fileName}`;
                          return <div
                            className={styles['link-select-image-wrapper']}
                            key={`link-image-${el.fileName}`}
                            onClick={() => {
                              const linksClone: MediaLink[] = _.cloneDeep(links);
                              let link = linksClone.filter((link) => link.fileName === linker.fileName)?.[0];
                              if (!link) {
                                link = {fileName: linker?.fileName, links: {}};
                              }
                              if (!(link.links)) {
                                link.links = {};
                              }
                              link.links[linkMedia] = el.fileName;
                              setLinkMedia("");
                              setLinks(linksClone);
                            }}
                          >
                            <img
                              className={styles['link-select-image']}
                              src={url}
                            />
                          </div>
                        })
                      }
                    </div>
                  </div>
                  <div className={styles['link-button-row']}>
                    <input
                      type={'button'}
                      className={styles['link-button']}
                      value={"CLOSE"}
                      onMouseDown={(e) => {
                        setLinkMedia("");
                      }}
                    >
                    </input>

                  </div>
                </div>

              : null
            }
          </div>

          : null
      }
      {
        add
          ?
          <div className={styles["add-wrapper"]}>
            <input value={addString} placeholder={'Enter new directory name...'} onChange={(e) => {setAddString(e.target.value)}} className={styles["add-input"]}/>
            <div className={styles["add-buttons"]}>
              <input className={styles["add-button"]} value={"OK"} onClick={addDir} type="button"/>
              <input className={styles["add-button"]} value={"Cancel"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
            </div>
          </div>
          : null
      }
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
