import React, { useEffect, useState } from "react";
import { Serial, AssignmentSerial } from "../interfaces/Entry";
import styles from "./assignScanner.module.scss";
import stylesGeneric from "../styles/generics.module.scss";
import { getCookie } from "../methods/cookie";
import { useNavigate } from "react-router-dom";
import {HomeButtonRow} from "../components/HomeButton/HomeButton";
import {Background} from "../components/background/Background";
import {logout} from "../methods/helpers";
import {ThreeDots} from "react-loader-spinner";
import {fetchWrapper} from "../methods/connector";
import {LoadingOverlay} from "../components/loadingOverlay/loading";
import {useDebouncedCallback} from "use-debounce";

const _ = require('lodash');

export const AssignScanner = () => {
  const navigate = useNavigate();
  const jwt = getCookie('jwt');
  const [serials, setSerials] = useState<string[]>([]);
  const [assignments, setAssignments] = useState<Record<string, AssignmentSerial[]>>({});
  const [users, setUsers] = useState<string[]>([]);
  const [currentSerial, setCurrentSerial] = useState<string>("");
  const [searchName, setSearchName] = useState<string>("");
  const [searchStringSerial, setSearchStringSerial] = useState<string>("");
  const [currentAssignment, setCurrentAssignment] = useState<AssignmentSerial>();
  const [newPermission, setNewPermission] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getAssignments = async(serial: string) => {
    let url = `${process.env.REACT_APP_URL}/assignmentsSerial/${serial}`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: AssignmentSerial[] = await data.json();
    const assignmentsClone: any = _.cloneDeep(assignments);
    assignmentsClone[serial] = r;
    setAssignments(assignmentsClone);

  }


  const searchStringSerialChange = (newString: string) => {
    setSearchStringSerial(newString);
    debounce(newString);
  }

  // prevent a search from happening unless the searchString didn't update in 1s
  const debounce = useDebouncedCallback(
    (serial) => {
      getData(serial)
    },
    1000
  )

  const getData = async(serial = "") => {
    let url = `${process.env.REACT_APP_URL}/serialList`;
    const query = [];

    if (serial !== "") {
      query.push(`serial=${serial}`);
    }

    if (query.length > 0) {
      url += '?'
      url = url + query.join('&')
    }
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'get');
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    const r: Serial[] = await data.json();
    setSerials(r.map(el => el.serial).sort());
  }


  const openSerial = async (serial: string) => {
    await getAssignments(serial);
    setCurrentSerial(serial);
  }

  const createAssignment = async (assignment: AssignmentSerial) => {
    let url = `${process.env.REACT_APP_URL}/sendAssignmentSerial`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment, new: true}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    setCurrentAssignment(undefined);
    setSearchName("");
    getAssignments(assignment.serial);
  }

  const sendAssignment = async(value: number, assignment: AssignmentSerial) => {
    let url = `${process.env.REACT_APP_URL}/sendAssignmentSerial`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment, permission: value, new: false}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    // setCurrentAssignment(undefined);
    getAssignments(assignment.serial);
  }

  const deleteAssignment = async(assignment?: AssignmentSerial) => {
    if (!assignment) {return}
    let url = `${process.env.REACT_APP_URL}/deleteAssignmentSerial`;
    setLoading(true);
    const data = await fetchWrapper(url, jwt, 'post', JSON.stringify({...assignment}));
    setLoading(false);
    if (data.status === 401) {
      logout();
      return;
    }
    if (data.status !== 200) {
      return;
    }
    setCurrentAssignment(undefined);
    getAssignments(assignment.serial);
  }

  useEffect(() => {
    const getSerials = async() => {
      let url = `${process.env.REACT_APP_URL}/serialList`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: Serial[] = await data.json();
      setSerials(r.map(el => el.serial).sort());
    }

    const getUsers = async() => {
      let url = `${process.env.REACT_APP_URL}/userList`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: string[] = await data.json();
      setUsers(r.sort());
    }

    // this is toggled twice, as the site is rendered twice
    // this is due to the strict mode being enabled (index.tsx)
    // this causes double-renders for dev-mode only
    if (jwt === '') {
      navigate('/auth');
      return;
    }
    // getData();
    getSerials();
    getUsers();
    const handleClickOutside = (event: MouseEvent) => {
      // if a click occurs that is not targeting a Dropdown-component, unselect the current dropdown target
      const t = event.target as HTMLDivElement;
      if (typeof t.className !== 'string'){
        return;
      }

      let a: ParentNode | null = t;
      const elements: any[] = [];
      while (a) {
        elements.unshift(a);
        a = a.parentNode;
      }
      const elementNames = elements.map((element) => element.className).filter(e => !!e);

      if (elementNames.filter(e => e.includes('assignment-row-permission')).length < 1) {
        setCurrentAssignment(undefined);
      }
      if (elementNames.filter(e => e.includes('assignment')).length < 1) {
        setCurrentSerial("");
      }
      if (elementNames.filter(e => e.includes('assignment-new')).length < 1) {
        setSearchName("")
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={stylesGeneric["page"]}>
      <Background/>
      <HomeButtonRow/>
      <div className={stylesGeneric["content-wrapper"]}>

        <div className={stylesGeneric["page-wrapper"]}>
          <table>
            <thead>
              <tr>
                <td>
                  <input
                    className={styles["search-bar"]}
                    value={searchStringSerial}
                    placeholder="Serial..."
                    onChange={(el) => searchStringSerialChange(el.currentTarget.value)}
                  >
                  </input>
                </td>
                <td>
                  Access
                </td>

              </tr>
            </thead>
            <tbody>
            {
              serials.map(el => {
                return(<tr className={stylesGeneric["table-row"]} key={el}>
                  <td className={stylesGeneric["table-cell"]}>
                    {el}
                  </td>
                  <td className={stylesGeneric["table-cell"]}>
                    <input
                      type="button"
                      className={stylesGeneric["dropdown-wrapper"]}
                      onClick={(e) => {e.stopPropagation(); openSerial(el)}}
                      value="Assign"
                    />
                  </td>
                </tr>)
              })
            }
            </tbody>
          </table>
        </div>
      </div>
      {
        currentSerial !== ""
        ?
          <div className={styles["assignment-wrapper"]}>
            <table className={styles["assignment-table"]}>
              <thead className={styles["assignment-thead"]}>
                <tr>
                  <td className={`${styles["assignment-head-user"]} ${styles['assignment-cell-inactive']}`}>
                    User
                  </td>
                  <td className={`${styles["assignment-head-permission"]} ${styles['assignment-cell-inactive']}`}>
                    Permission
                  </td>
                  <td className={`${styles["assignment-head-delete"]} ${styles['assignment-cell-inactive']}`}>
                    {/*CLOSE*/}
                  </td>

                </tr>
              </thead>
              <tbody className={styles["assignment-tbody"]}>
                {
                  (assignments[currentSerial] ?? []).map(el => {
                    return <tr className={styles["assignment-row"]} key={`${el.serial}${el.username}`}>
                      <td className={styles["assignment-row-user"]}>
                        {el.username}
                      </td>
                      <td className={styles["assignment-row-permission"]}>
                        <input
                          type={"number"}
                          value={currentAssignment?.username === el.username ? newPermission : el.permission}
                          onFocus={() => {setNewPermission(el.permission); setCurrentAssignment(el)}}
                          onChange={(e) =>
                            {
                              setNewPermission(Math.max(0, Math.min(999, Number(e.target.value))))}
                          }
                          onKeyDown={ async (e) => {
                            if (e.key !== 'Enter' || !currentAssignment) {
                              return;
                            }
                            await sendAssignment(newPermission, currentAssignment);
                          }}
                        />
                        {
                          currentAssignment?.serial === el.serial && currentAssignment.username === el.username
                          ? <div className={styles['assignment-row-permission-hint']}>
                              Press "Enter" to confirm.
                            </div>
                          : null
                        }
                      </td>
                      <td className={styles["assignment-row-delete"]} onClick={() => {
                        if (window.confirm(`Delete assignment of user ${el.username}?`)) {
                          deleteAssignment(el)
                        }
                      }}>
                        X
                      </td>
                    </tr>
                  })
                }
                <tr className={styles['assignment-row']}>
                  <td className={styles["assignment-row-user"]}>
                    <input className={styles["assignment-new"]} placeholder="..." value={searchName} onChange={(e) => {setSearchName(e.target.value)}}>
                    </input>
                    {
                      (searchName !== "" && searchName !== undefined)
                      ?
                        <div className={styles["assignment-new-wrapper-outer"]}>
                          <div className={styles["assignment-new-wrapper"]}>
                            {users.filter(el => el.toLowerCase().includes(searchName.toLowerCase() || "")).map(el => {
                              return <div key={el} className={styles["assignment-new-option"]} onClick={() => {
                                createAssignment({username: el, serial: currentSerial, permission: 1})
                              }}>
                                {el}
                              </div>
                            })}
                          </div>
                        </div>
                        : null
                    }
                  </td>
                  <td className={styles['assignment-cell-inactive']}/>
                  <td className={styles['assignment-cell-inactive']}/>
                </tr>
              </tbody>
            </table>
            <div className={styles['assignment-button-row']}>
              <input
                type={'button'}
                className={styles['assignment-button-row-close']}
                value={"CLOSE"}
                onMouseDown={(e) => {
                  setCurrentAssignment(undefined);
                  setCurrentSerial("");
                  setSearchName("")
                }}
              >
              </input>
            </div>
          </div>
        : null
      }
      <LoadingOverlay loading={loading}/>
    </div>
  )
}
