import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import  styles from "./render.module.scss";
import {fetchDesign} from "../../methods/connector";
import {getCookie} from "../../methods/cookie";
import {AppConfig, AppElement} from "../../interfaces/AppConfig";
import {useParams} from "react-router-dom";
import {genElements, parseURLs} from "../../methods/renderUtils";
import {Spinner} from "../../components/spinner/spinner";
import {Loader} from "../../components/loader/loader";
import {fillConfig, registerElements, registerTemplates} from "../../methods/templateUtils";

const _ = require('lodash');

export const PreviewRenderer = () => {

  const jwt = getCookie('jwt');
  const routeParams = useParams();
  const designTag = routeParams.designTag;
  const stageName = routeParams.stageName;
  // const [design, setDesign] = useState<AppConfig>();
  const design = useRef<AppConfig>();
  const [e, setElements] = useState<Record<string, AppElement>>({});
  const [pC, setPageConfig] = useState<any>({});

  const renderComponent = (element: AppElement) => {
    let component;

    switch (element.elementType) {
      case 'button': {
        if (element.css) {
          if (!element.css?.border) {
            element.css.border = 'none';
          }
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
          // element.css.cursor = 'pointer'
        }
        component = <button
          className={styles['genericElement']}
          style={element.css} >
          {element.maintitle}
        </button>
        break;
      }

      case 'functionButton': {
        if (element.css) {
          if (!element.css?.border) {
            element.css.border = 'none';
          }
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
          // element.css.cursor = 'pointer'
        }
        component = <button
          className={styles['genericElement']}
          style={element.css}>
          {element.maintitle}
        </button>
        break;
      }

      case 'checkbox': {
        if (element.css) {
          if (!element.css?.margin) {
            element.css.margin = '0';
          }
        }
        component = <input
          className={styles['genericElement']}
          type={"checkbox"} style={element.css}>
        </input>
        break;
      }

      case 'barcode':
      case 'input': {
        if (element.css) {
          if (!element.css?.padding) {
            element.css.padding = '0';
          }
          if (!element.css?.border) {
            element.css['box-sizing']= 'border-box';
          }
        }
        component = <input
          className={styles['genericElement']}
          style={element.css}>
        </input>
        break;
      }

      case 'options': {
        component = <select
          className={styles['genericElement']}
          style={element.css}>
          {
            element.handleFunction?.argument?.replace(' ', '').split(',').map((val, index) => {
              return (<option
                  className={styles['genericElement']}
                  key={index}>
                  {val}
                </option>
              )
            })
          }
          {element.maintitle}
        </select>
        break;
      }

      case 'spinner': {
        component = <Spinner
          className={styles['genericElement']}
          style={element.css}
          parentStyles={styles}
        />
        break;
      }
      case 'h2':
      case 'p':
      case 'note': {
        if (element.css) {
          if (!element.css?.border) {
            element.css.border = 'none';
          }
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
        }
        component = <div
          className={styles['genericElement']}
          style={element.css}
        >
          {element.maintitle}
        </div>
        break;
      }

      case 'resource': {
        component =
          <div>
            <object
              className={styles['genericElement']}
              data={element.resource}
              style={element.css}>
            </object>

          </div>
        break;
      }

      case 'video': {
        component = <video
          className={styles['genericElement']}
          loop={true}
          autoPlay={true}
          playsInline={true}
          muted={true}
          style={{...element.css, "border": "solid black 1px"}}

        >
          < source
          src = {element.resource}
          type = "video/mp4"
          />
        </video>
        break;
      }

      case 'link': {
        if (element.css) {
          if (!element.css?.border) {
            element.css.border = 'none';
          }
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
        }
        component = <a
          className={styles['genericElement']}
          href={element.text}
          style={element.css}>
          {element.maintitle}
        </a>
        break;
      }

      case 'progressBar': {
        if (element.css) {
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
          element.css.border = 'solid black 2px';
        }
        component = <Loader
          className={styles['genericElement']}
          style={element.css}
          parentStyles={styles}
        />
        break;
      }

      case 'iframePost':
      case 'iframe': {
        if (element.css) {
          if (!element.css?.border) {
            element.css.border = 'none';
          }
          if (!element.css?.outline) {
            element.css.outline = 'none';
          }
        }
        component = <iframe
          sandbox="
            allow-forms
            allow-modals
            allow-pointer-lock
            allow-popups
            allow-popups-to-escape-sandbox
            allow-same-origin
            allow-scripts
            allow-top-navigation
            "
          allowFullScreen
          className={styles['genericElement']}
          style={element.css}
          src={element.design}
          name="iFrame"
        ></iframe>
        break;
      }
    }
    return component;
  }

  const updateSize = () => {
    if (design.current) {
      const {elements, pageConfig} = genElements(_.cloneDeep(design.current));
      if (elements) {
        setElements(elements);
        setPageConfig(pageConfig)
      }
    }
  }

  useEffect(() => {
    const getDesigns = async() => {
      if (!designTag) {
        return;
      }
      const r = await fetchDesign(designTag, jwt);
      if (!r) {
        return;
      }
      const conf = r.configs?.[stageName ?? ''];
      design.current = conf;
      if (conf && stageName) {
        await parseURLs(conf);
        const designsValidate = _.cloneDeep(r);
        let _t, _e;
        designsValidate!.configs[stageName] = design.current;
        [designsValidate.templates, _t] = registerTemplates(designsValidate);
        [designsValidate.templatesElements, _e] = registerElements(designsValidate);
        const confFilled = fillConfig(conf, designsValidate.templates, designsValidate.templatesElements);
        const {elements, pageConfig} = genElements(confFilled);
        if (elements) {
          setElements(elements);
          setPageConfig(pageConfig)
        }
      }
    }
    getDesigns();
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    // updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  const bg = pC?.background ?? "";

  return (
    <div className={styles['windowWrapper']}
    >
      <div
        style={{
          background: bg,
          height: '100vh', width: '100vw', zoom: 1, position: "absolute",
          transform: `translate(${pC.x ?? 0}px, ${pC.y ?? 0}px) scale(${pC.scaleX ?? 1}, ${pC.scaleY ?? 1})`,
          WebkitTransition: `translate(${pC.x ?? 0}px, ${pC.y ?? 0}px) scale(${pC.scaleX ?? 1}, ${pC.scaleY ?? 1})`,
        }}
      />
      {
        Object.entries(e).map(([key, val]) =>
        {
          return (
            <div key={key}>
              {
                renderComponent(val)
              }
            </div>
          )
        }
        )
      }
    </div>
  )
}
