import {ThreeDots} from "react-loader-spinner";
import React from "react";
import  styles from "./loading.module.scss";


export const LoadingOverlay = (props: {loading: boolean}) => {
  return props.loading
    ? <div className={styles['loading-overlay']}>
      <ThreeDots
        width={'100%'}
        color={'#009900'}
        wrapperClass={styles['loading-dots']}
      />
    </div>
    : null
}
