import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom"
import { Auth } from './screens/login';
import { SelectDesign } from './screens/selectDesign';
import { AssignDesign } from './screens/assignDesign';
import { AssignScanner } from './screens/assignScanner';
import { Home } from './screens/home';
import {Index} from "./screens/configGenerator";
import {ConfigMenu} from "./screens/configGenerator/menu";
import {ConfigEditor} from "./screens/configGenerator/config";
import {PreviewRenderer} from "./screens/renderer/render";
import {Uploader} from "./screens/uploader/uploader";
import {AdminArea} from "./screens/admin/admin";
import {CreateScanner} from "./screens/admin/createScanner";
import {LanguageMenu} from "./screens/language/menu";
import {LanguageEditor} from "./screens/language/edit";
import {LanguageTranslator} from "./screens/language/translate";
import {PermissionScreen} from "./screens/permission/permission";
import {VerifyUser} from "./screens/admin/verifyUser";
import {QualityView} from "./screens/admin/qualityView";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    loader: (() => redirect("/home")),
    // element: <div>1</div>
  },
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/admin",
    element: <AdminArea/>
  },
  {
    path: "/admin/calibrationQuality",
    element: <QualityView/>
  },
  {
    path: "/admin/createScanner",
    element: <CreateScanner/>
  },
  {
    path: "/admin/verifyUser",
    element: <VerifyUser/>
  },
  {
    path: "/auth",
    element: <Auth/>
  },
  {
    path: "/selectDesign",
    element: <SelectDesign/>
  },
  {
    path: "/assignDesign",
    element: <AssignDesign/>
  },
  {
    path: "/assignScanner",
    element: <AssignScanner/>
  },
  {
    path: "/generateConfig",
    element: <Index/>
  },
  {
    path: "/generateConfigLanguage/:designTag/:language",
    element: <LanguageEditor/>
  },
  {
    path: "/generateConfigLanguage/:designTag/translate/:language",
    element: <LanguageTranslator/>
  },
  {
    path: "/generateConfigLanguage/:designTag",
    element: <LanguageMenu/>
  },
  {
    path: "/generateConfig/:designTag",
    element: <ConfigMenu/>
  },
  {
    path: "/generateConfig/:designTag/:stageName",
    element: <ConfigEditor/>
  },
  {
    path: "/generateConfig/:designTag/:stageName/preview",
    element: <PreviewRenderer/>
  },
  {
    path: "/upload",
    element: <Uploader/>
  },
  {
    path: "/generateConfigPermission/:designTag",
    element: <PermissionScreen/>
  },
  {
    path: "/generateConfigPermission/:designTag/:stageName",
    element: <PermissionScreen/>
  },
])

root.render(
  <React.StrictMode>
    <RouterProvider router = {router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
