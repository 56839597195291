import styles from "./admin.module.scss";
import {Background} from "../../components/background/Background";
import {openTab} from "../../methods/helpers";


export const AdminArea = (props: any) => {
  return <div className={styles['home-wrapper']}>
    <Background/>
    <div className={styles['home-content']}>
      <div className={styles['home-links']}>
        <h2>
          Review calibration.
        </h2>
        <p>
          This site will allow for review of the quality of calibration runs, as well as the performance of users that participated in your runs.
        </p>
        <input type="button" className={styles['home-link']} value={"View calibration qualities"} onMouseDown={(e) => {
          openTab(e, '/admin/calibrationQuality')
        }}>
        </input>

        <h2>
          Add scanner(s).
        </h2>
        <p>
          This site will allow for creation of new scanners, and assign them to a user with a given design.
        </p>
        <input type="button" className={styles['home-link']} value={"Add scanners"} onMouseDown={(e) => {
          openTab(e, '/admin/createScanner')
        }}>
        </input>

        <h2>
          Verify user(s).
        </h2>
        <p>
          This site will allow for verifying new users.
        </p>
        <input type="button" className={styles['home-link']} value={"Verify user"} onMouseDown={(e) => {
          openTab(e, '/admin/verifyUser')
        }}>
        </input>

      </div>
    </div>
  </div>
}
