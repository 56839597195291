import  styles from "./spinner.module.scss";
import {useEffect, useLayoutEffect, useRef} from "react";

export const Spinner = (props: {
  parentStyles: any,
  className: string,
  style?: Record<string, string>
}) => {

  const refOuter = useRef<HTMLDivElement>(null);
  const refInner = useRef<HTMLDivElement>(null);

  const squareCircle = () => { // target_el: child, source_el: parent
    const percents = 100;
    const target_element = refInner.current;
    const source_element = refOuter.current;
    if (!target_element || !source_element) {
      return;
    }
    let h = Math.min(source_element.getBoundingClientRect().width, source_element.getBoundingClientRect().height);
    target_element.style.width = (h * percents / 100) + 'px';
    target_element.style.height = (h * percents / 100) + 'px';
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', squareCircle);
    squareCircle();
    return () => window.removeEventListener('resize', squareCircle);
  }, []);

  return <div className={props.parentStyles[props.className]} style={props.style} ref={refOuter}>
    <div className={styles['spinner']} ref={refInner}>

    </div>
  </div>
}
