import React, {useEffect, useRef, useState} from "react";
import  styles from "./Dropdown.module.scss";
import stylesConfig from "../../screens/configGenerator/config.module.scss"
import {openTab} from "../../methods/helpers";


const colorBG = '#BBB';

export const Dropdown = (props: {
  currentVal: string,
  valueList: string[],
  wide?: boolean,
  onChange: ((val: string) => void),
  translate?: ((e: string) => string),
  designTag?: string,
  allowOther?: boolean,
  readOnly?: boolean,
  type?: string,
  requiresEnter?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchVal, setSearchVal] = useState<string>("");
  const [matches, setMatches] = useState<string[]>([]);

  const onMouse = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.button === 0) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    if (
      !(e.button === 1
        && props.designTag !== undefined
        && !([undefined, null, ""].includes(props.currentVal))
      )) {
      return;
    }

    openTab(e, `/generateConfig/${props.designTag}/${props.currentVal}`)
  }

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)){
        if (!props.allowOther) {
          setSearchVal("");
        }
        getMatches(event);
        return;
        //@ts-ignore
      } else if (event.target.tagName!== "LI") {
        getMatches(event);
      }
    }    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, props.valueList]);

  const setSearchValWrapper = (val: string) => {
    setSearchVal(val);
    getMatches(undefined, val);
    if(props.allowOther && (!(props.requiresEnter))) {
      props.onChange(val);
    }
  }
  const getMatches = (event?: Event, search?: string) => {
    const currentVal = search ?? searchVal
    if (event) {
      const isActive = event.target === inputRef.current;
      if (currentVal === "" && !isActive) {
        setMatches([]);
        return;
      }
    }
    setMatches([...props.valueList.sort(), 'clear'].filter((val) => val.toLowerCase().includes(currentVal.toLowerCase())));
  }

  const handleChange = (val: string) => {
    setSearchVal("");
    props.onChange(val === 'clear' ? '' : val);
    setMatches([]);
  }

  // getMatches(inputRef);
  // const matches = getMatches()

  return <div className={styles['wrapper']} ref={wrapperRef}>
    <input
      className={stylesConfig[`${props.wide ? 'value-input-string-wide' : 'value-input-string'}` ]}
      ref={inputRef}
      value={searchVal}
      placeholder={props.type === 'password' ? '' : props.currentVal.toString()}
      onChange={(e) => {setSearchValWrapper(e.target.value)}}
      onKeyDown={(e) => {if (e.key !== "Enter" || !props.requiresEnter) {return}; props.onChange(searchVal); setSearchValWrapper('')}}
      onMouseDown={(e) => {onMouse(e)}}
      onContextMenu={(e) => {e.preventDefault()}}
      contextMenu={'false'}
      type={props.type}
      readOnly={props.readOnly}
      style={props.readOnly ? {backgroundColor: colorBG} : {}}
    />
    {
      matches.length > 0 && !props.readOnly
      ?
        <ul className={styles['options']}>
          {matches.map((val) => {
            return <li
              className={val === props.currentVal ? `${styles['option']} ${styles['option-active']}` : styles['option']}
              key={val}
              onClick={() => {handleChange(val)}}
            >
              {props.translate ? props.translate(val) : val}
            </li>
          })}
        </ul>
      :null
    }
  </div>
}
