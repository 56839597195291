import {AppConfig, AppDesign, AppElement} from "../interfaces/AppConfig";
import {fetchLocations} from "./connector";

const fixPositionPx = (
  imageWidth: number,
  imageHeight: number,
  screenWidth: number,
  screenHeight: number,
  css: {[key: string]: string},
  oldWidthPx?: string,
  oldHeightPx?: string,
  oldLeftPx?: string,
  oldTopPx?: string,

) => {
  const returnObj = {width: oldWidthPx, height: oldHeightPx, left: oldLeftPx, top: oldTopPx}
  if (!imageWidth || !imageHeight) {
    return returnObj
  }
  const oldWidth = oldWidthPx !== undefined ? Number((oldWidthPx ?? "").replace('px', "")) : NaN;
  const oldHeight = oldHeightPx !== undefined ? Number((oldHeightPx ?? "").replace('px', "")) : NaN;
  const oldLeft = oldLeftPx !== undefined ? Number((oldLeftPx ?? "").replace('px', "")) : NaN;
  const oldTop = oldTopPx !== undefined ? Number((oldTopPx ?? "").replace('px', "")) : NaN;


  const imageRatio = imageWidth / imageHeight;
  const screenRatio = screenWidth / screenHeight;

  const imageScale = screenRatio > imageRatio ? screenHeight / imageHeight : screenWidth / imageWidth;
  const oldX = oldLeft * imageScale;
  const oldY = oldTop * imageScale;
  const deltaW = (screenWidth - imageWidth * imageScale) / 2;
  const deltaH = (screenHeight - imageHeight * imageScale) / 2;
  returnObj.width = !isNaN(oldWidth) ? `${oldWidth * imageScale}px` : undefined;
  returnObj.left = !isNaN(oldLeft) ? `${oldX + deltaW}px` : undefined;
  returnObj.height = !isNaN(oldHeight) ? `${oldHeight * imageScale}px` : undefined;
  returnObj.top = !isNaN(oldTop) ? `${oldY + deltaH}px` : undefined;

  // for all additional keys in the css, if they include 'px', scale the preceeding number
  for (const [key, val] of Object.entries(css)) {
    if (val.replace(/ /g, '').slice(-2).includes('px')) {
      const valSplit = val.split(' ');
      for (let i=0; i<valSplit.length; i++) {
        const v = valSplit[i];
        if (!(v.includes('px'))){
          continue;
        }
        valSplit[i] = `${Number(v.replace('px', '')) * imageScale}px`;
      }
      css[key] = valSplit.join(' ');
    }
  }
  return returnObj;
}


const getDims = (bgRes: string, backgroundSize: AppDesign['backgroundSize'], w: number, h: number) => {
  if (!backgroundSize) {
    return '';
  }

  const imWidth = backgroundSize.width ?? 1;
  const imHeight = backgroundSize.height ?? 1;


  if (!backgroundSize.hasBorder) {
    const r1 = imWidth / imHeight;
    const r2 = w / h;
    return (bgRes && bgRes !== 'url()') ? `${bgRes} center center/${r1 > r2 ? `100% auto` : `auto 100%`} no-repeat` : '';

  }

  if (
    !backgroundSize.sectionWidth
    || !backgroundSize.sectionHeight
    || !backgroundSize.width
    || !backgroundSize.height
    || backgroundSize.sectionX === undefined
    || backgroundSize.sectionY === undefined
  ) {
    return ''
  }
  let viewPortWidth = backgroundSize.sectionWidth;
  let viewPortHeight = backgroundSize.sectionHeight;
  let posX = backgroundSize.sectionX;
  let posY = backgroundSize.sectionY;
  if (!backgroundSize.hasBorder) {
    viewPortWidth = imWidth;
    viewPortHeight = imHeight;
    posX = 0;
    posY = 0;
  }

  const r1 = viewPortWidth / viewPortHeight;
  const r2 = w / h;
  const imScale = r1 > r2 ? viewPortWidth / w : viewPortHeight / h;
  const scale = r1 > r2 ? imWidth / viewPortWidth : imHeight / viewPortHeight;
  const x = (w - (viewPortWidth / imScale))/2 - posX / imScale;
  const y = (h - (viewPortHeight / imScale))/2 - posY / imScale;
  return (bgRes && bgRes !== 'url()') ? `${bgRes} ${x}px ${y}px/${r1 > r2 ? `${scale * 100}% auto` : `auto ${scale * 100}%`} no-repeat` : '';
}


/**
 * rescale the background image to fit into the screen perfectly, and move it to the upper left corner
 * then, calculate the needed scale- and x/y-offset to fit the viewport requirements
 * @param bgRes
 * @param backgroundSize
 * @param w
 * @param h
 */
const getDimsBG = (bgRes: string, backgroundSize: AppDesign['backgroundSize'], w: number, h: number) => {
  const imWidth = backgroundSize?.width;
  const imHeight = backgroundSize?.height;
  let viewPortWidth = backgroundSize?.sectionWidth;
  let viewPortHeight = backgroundSize?.sectionHeight;
  let posX = backgroundSize?.sectionX;
  let posY = backgroundSize?.sectionY;
  if (!imWidth || !imHeight) {
    return {
      bgString: (bgRes && bgRes !== 'url()') ? `${bgRes} 0 0 /  auto auto no-repeat` : '',
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      x: 0,
      y: 0
    };
  }
  if (!backgroundSize?.hasBorder) {
    viewPortWidth = imWidth;
    viewPortHeight = imHeight;
    posX = 0;
    posY = 0;
  }

  if (viewPortWidth === undefined || viewPortHeight === undefined || posX === undefined || posY === undefined) {
    return {
      bgString: (bgRes && bgRes !== 'url()') ? `${bgRes} 0 0 /  auto auto no-repeat` : '',
      scale: 1,
      scaleX: 1,
      scaleY: 1,
      x: 0,
      y: 0
    };
  }

  const r1 = viewPortWidth / viewPortHeight;
  const r2 = w / h;
  const imScaleX = imWidth / w;
  const imScaleY = imHeight / h;
  const imScale = r1 > r2 ? imScaleX : imScaleY;
  let scaleX = imWidth / viewPortWidth;
  let scaleY = imHeight / viewPortHeight;
  let scale = r1 > r2 ? scaleX : scaleY;
  switch (backgroundSize.scaleMode) {
    case 'contain': scaleX = scale; scaleY = scale; break;
    case 'cover': scale *= (r1 < r2 ? r2 / r1 : r1 / r2); scaleX = scale; scaleY = scale; break;
    case 'fit-width': scale *= (r1 < r2 ? r2 / r1 : 1); scaleX = scale; scaleY = scale; break;
    case 'fit-height': scale *= (r1 < r2 ? 1 : r1 / r2); scaleX = scale; scaleY = scale; break;
    case 'stretch': scaleX = scale * (r1 < r2 ? (r2 / r1) : 1) ; scaleY = scale * (r1 < r2 ?  1 : (r1 / r2)); break;
    default: scaleX = scale; scaleY = scale; break;
  }


  const x = (w / 2 - (posX + viewPortWidth / 2) / imScale);
  const y = (h / 2 - (posY + viewPortHeight / 2) / imScale);
  return {
    bgString: (bgRes && bgRes !== 'url()') ? `${bgRes} 0 0 /  ${r1 > r2 ? `${100}vw auto` : `auto ${100}vh`} no-repeat` : '',
    scale,
    scaleX,
    scaleY,
    x: x * scaleX,
    y: y * scaleY
  };
}

export const genElements = (page: AppConfig) => {
  if (!page) {
    return {elements: undefined, pageConfig: undefined};
  }
  const elements: Record<string, AppElement> = { ...page.design.elements } ?? {};
  let imageWidth = page.design.backgroundSize?.hasBorder ? page.design.backgroundSize?.sectionWidth : page?.design.backgroundSize?.width;
  let imageHeight = page.design.backgroundSize?.hasBorder ? page.design.backgroundSize?.sectionHeight : page?.design.backgroundSize?.height;
  imageWidth = imageWidth ?? 1;
  imageHeight = imageHeight ?? 1;

  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const ratio = imageWidth / imageHeight;
  const screenRatio = screenWidth / screenHeight;

  // if (page?.design.backgroundBounds?.goTo) {
  //   const bounds = page?.design.backgroundBounds;
  //   const max = (bounds.maxWidth ?? 1) / (bounds.maxHeight ?? 1);
  //   const min = (bounds.minWidth ?? 1) / (bounds.minHeight ?? 1);
  //   // if (screenRatio > max || screenRatio < min) {
  //   //   return genElements(bounds.goTo);
  //   // }
  // }

  for (const [i, e] of Object.entries(elements)) {
    if (e.ignoreAdjust || !e.css) {
      continue;
    }
    const {width, height, left, top} = fixPositionPx(imageWidth, imageHeight, screenWidth, screenHeight, e.css, e.css.width, e.css.height, e.css.left, e.css.top);
    if (width){
      e.css.width = width;
    }
    if (height) {
      e.css.height = height;
    }
    if (left){
      e.css.left = left;
    }
    if (top) {
      e.css.top = top;
    }

    if (!e.css['z-index']) {
      e.css['z-index'] = '10';
    }

    //todo
    if (e.resource && !(e.resource.includes("://"))) {
      e.resource = `/${e.resource}`;
    }

    if (e.background?.image && e.background.image !== '' && e.background.image !== 'none') {
      let buttonWidth;
      let buttonHeight;
      if (e.css.width.includes('px')) {
        buttonWidth = Number(e.css.width.replace('px', '') ?? '');
      } else if (e.css.width.includes('%')) {
        buttonWidth = Number(e.css.width.replace('%', '') ?? '') / 100 * screenWidth;
        // } else {
        //   continue;
      }

      if (e.css.height.includes('px')) {
        buttonHeight = Number(e.css.height.replace('px', '') ?? '');
      } else if (e.css.height.includes('%')) {
        buttonHeight = Number(e.css.height.replace('%', '') ?? '') / 100 * screenHeight;
      }


      if (buttonWidth && buttonHeight) {
        let bg = e.background.image;
        if (!(bg.includes("://"))) {
          bg = `/${bg}`
        }
        const buttonBG = getDims(`url(${bg})`, e.background, buttonWidth, buttonHeight);
        // const {bgString, scale, scaleX, scaleY , x, y} = getDimsBG(`url(${bg})`, e.background, buttonWidth, buttonHeight);
        // e.css.background = bgString;
        // e.css.transform = `translate(${x}px, ${y}px) scale(${scaleX}, ${scaleY})`;
        // e.css['-webkit-transform'] = `translate(${x}px, ${y}px) scale(${scaleX}, ${scaleY})`;
        e.css.background = buttonBG;
        e.css['object-fit'] = 'fill';
      } else {
        let bg = e.background.image;
        if (!(bg.includes("://"))) {
          bg = `/${bg}`
        }
        e.css.background = bg && bg !== '' ? `url(${bg})` : '';
      }
    }

    if ((e.elementType === 'iframe' || e.elementType === 'iframePost') && e.design !== undefined) {
      let url = e.design;
      while (url.includes('${')) {
        // TODO
        // const index0 = url.indexOf('${');
        // const index1 = url.indexOf('}');
        // const searchStr = url.slice(index0 + 2, index1);
        // const searchStrSplit = searchStr.split('.');
        // let recState: any = localState;
        // for (const searchStrIt of searchStrSplit) {
        //   recState = recState[searchStrIt];
        //   if (!recState) {
        //     break;
        //   }
        // }
        // recState = recState ?? '';
        // url = `${url.slice(0, index0)}${recState}${url.slice(index1 + 1)}`;
        url = ""
      }
      e.design = url;
    }
  }
  const pageConfig: any = {};
  let bgRes = page.design?.background ?? '';
  if (page.design?.backgroundType !== 'iframe') {
    if (bgRes && bgRes !== '') {
      if (bgRes.startsWith('#')) {
        pageConfig['background'] = bgRes;
      } else {
        if (!(bgRes.includes("://"))) {
          bgRes = `/${bgRes}`
        }
        const {bgString, scale, scaleX, scaleY , x, y} =
          getDimsBG(`url(${bgRes})`, page.design.backgroundSize, screenWidth, screenHeight);
        pageConfig['background'] = bgString;
        pageConfig['scale'] = scale;
        pageConfig['scaleX'] = scaleX;
        pageConfig['scaleY'] = scaleY;
        pageConfig['x'] = x;
        pageConfig['y'] = y;
        pageConfig['--background'] = 'no-repeat';
      }
    }
  } else {
    elements['backgroundIframeCalculate'] = {
      background: undefined,
      css: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: '0px',
        top: '0px',
        border: '0'
      },
      design: bgRes,
      elementType: 'iframe',
      goTo: '',
      ignoreAdjust: false,
      resource: '',
      subtitle: '',
      maintitle: 'BG'

    }
  }

  pageConfig['heading'] = page?.design.heading ?? '';

  return { elements, pageConfig };

};

const replaceUrl = (host: string, url: string) => {
  return `https://${host}/${url}`;
}

export const parseURLs = async(conf: AppConfig) => {
  let names: string[] = [];
  if (conf.design.background && conf.design.backgroundType === 'image') {
    names.push(conf.design.background);
  }
  for (const el of Object.values(conf.design.elements)) {
    if (el.resource) {
      names.push(el.resource)
    }

    if (el.background?.image && el.background.image !== '' && el.background.image !== 'none') {
      names.push(el.background.image)
    }
  }
  names = names.filter((el) => el !== "" && !(el.includes('/')))
  // names = names.filter((el) => el !== "" );
  if (names.length === 0) {
    return;
  }
  const mapping = await fetchLocations(names);
  if (!mapping) {
    return;
  }

  if(conf.design.background && mapping[conf.design.background]?.['loc']?.length > 0) {
    conf.design.background = replaceUrl(mapping[conf.design.background]['loc'][0], conf.design.background);
  }

  for (const el of Object.values(conf.design.elements)) {
    if (el.resource && mapping[el.resource]?.['loc']?.length > 0) {
      el.resource = replaceUrl(mapping[el.resource]['loc'][0], el.resource);
    }

    if (el.background?.image && el.background.image !== '' && el.background.image !== 'none') {
      if (mapping[el.background.image]?.['loc']?.length > 0) {
        el.background.image = replaceUrl(mapping[el.background.image]?.['loc']?.[0], el.background.image);
      }
    }
  }
}

export const extractURLs = async (conf: AppConfig) => {
  let names: string[] = [];
  if (conf.design.background && conf.design.backgroundType === 'image') {
    names.push(conf.design.background);
  }
  for (const el of Object.values(conf.design.elements)) {
    if (el.resource) {
      names.push(el.resource)
    }

    if (el.background?.image && el.background.image !== '' && el.background.image !== 'none') {
      names.push(el.background.image)
    }
  }

  names = names.filter((el) => el !== "" && !(el.includes('/')));
  names = names.filter((v, i, a) => a.indexOf(v) === i);
  if (names.length > 0) {
    const mapping = await fetchLocations(names);
    if (mapping) {
      Object.entries(mapping).forEach(([key, val]) => {
        const index = names.indexOf(key);
        if (index < 0) {
          return;
        }
        names[index] = `https://${val.loc[0]}/${val.alternateName}`;
      })
    }
  }
  return names;
}

export const fetchURLs = async(urls: string[]) => {
  const resp = await Promise.all(urls.map(async (url) => {
    if (!(url.includes("/"))) {
      return url;
    }
    const res = await fetch(url, {method: "get"});
    if (res.status === 200) {
      return "";
    }
    return url;
  }));
  return resp.filter((url) => url !== "");
}
