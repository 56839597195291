import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LanguageButton.module.scss"
import {faHome} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export const LanguageButton = () => {
  let lan = window.localStorage.getItem('language') ?? 'us';
  const lanList = ['us', 'jp'];
  let img = require(`../../resources/images/languages/${lan}.svg`)
  return <button className={styles['button-wrapper']}
  >
    <img
      className={styles['button']}
      src={img}
      onClick={() => {
        const index = lanList.indexOf(lan);
        const newIndex = (index+1)%lanList.length;
        lan = lanList[newIndex];
        img = require(`../../resources/images/languages/${lan}.svg`);
        window.location.reload();
        localStorage.setItem('language', lan);
      }}
    />
  </button>
}
