import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import stylesGeneric from "../../styles/generics.module.scss";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {openTab} from "../../methods/helpers";

export const HomeButton = () => {
  const navigate = useNavigate();
  return <button
    className={stylesGeneric['generic-button-wrapper']}
    onMouseDown={(e) => openTab(e, '/home')}
  >
    <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faHome}/>
  </button>
}

export const HomeButtonRow = () => {
  return <div className={stylesGeneric["settings-button-row"]}>
    <div className={stylesGeneric["settings-button"]}>
      <HomeButton/>
    </div>
  </div>
}
