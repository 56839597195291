import stylesGeneric from "../../styles/generics.module.scss";
import {Background} from "../../components/background/Background";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import styles from "./createScanner.module.scss";
import React, {ChangeEvent, useEffect, useState} from "react";
import {logout} from "../../methods/helpers";
import {Design} from "../../interfaces/Entry";
import {getCookie} from "../../methods/cookie";
import {useNavigate} from "react-router-dom";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {ThreeDots} from "react-loader-spinner";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";
import {fetchWrapper} from "../../methods/connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import readXlsxFile from "read-excel-file";

export const CreateScanner = () => {
  const jwt = getCookie('jwt');

  const [designs, setDesigns] = useState<string[]>([]);
  const [users, setUsers] = useState<string[]>([]);
  const [targetSerials, setTargetSerials] = useState<string>('');
  const [targetUser, setTargetUser] = useState<string>("");
  const [targetDesign, setTargetDesign] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);


  const splitSerials = () => {
    let curString = targetSerials.replace(/ /g, '');
    const match = curString.search('\\W+');
    if (match < 0) {
      return [curString];
    }
    const char = curString[match];
    return curString.split(char);
  }
  const sendSerials = async () => {
    let url = `${process.env.REACT_APP_URL}/serialsAdd`;
    const sendSerials = splitSerials();
    if (
      !(users.includes(targetUser))
      || !(designs.includes(targetDesign))
      || sendSerials.length === 0
    ) {
      return;
    }
    setLoading(true);
    const response = await fetchWrapper(url, jwt, 'post',
      JSON.stringify({targetSerials: sendSerials, targetUser: [targetUser], targetDesign}));
    setLoading(false);
    if (response.status === 401) {
      logout();
      return;
    }
    if (response.status !== 200) {
      return;
    }

    const resp = await response.json();
    window.alert(resp.detail);
    return;

  };

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!(e.target.files) || !e.target.files[0]){
      return;
    }
    let IDs: string[] = [];

    readXlsxFile(e.target.files[0]).then(async (rows) => {
      rows.forEach((row) => {
        IDs.push((row[1] ?? '').toString());
        console.log();
      })
      IDs = IDs.filter((el) => (
        el.length === 20
        && !isNaN(Number(el.slice(2, 4))))
      );
      IDs = IDs.filter((v, i, a) => a.indexOf(v) === i);

      setLoading(true);
      let url = `${process.env.REACT_APP_URL}/serialsAdd`;
      const response = await fetchWrapper(url, jwt, 'post',
        JSON.stringify({targetSerials: IDs, targetUser: ['skutzner', 'kagome_hayashi', 'kagome_matsunaga'], targetDesign: '2408 Normal'}));
      setLoading(false);
      if (response.status === 401) {
        logout();
        return;
      }
      if (response.status !== 200) {
        return;
      }

      const resp = await response.json();
      window.alert(resp.detail);
      return;
    })
  };


  useEffect(() => {
    const getDesigns = async() => {
      let url = `${process.env.REACT_APP_URL}/designList?name_only=true`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: Design[] = await data.json();
      setDesigns(r.map(el => el.designTag).sort());
    }

    const getUsers = async() => {
      let url = `${process.env.REACT_APP_URL}/userList`;
      const data = await fetchWrapper(url, jwt, 'get');
      if (data.status === 401) {
        logout();
        return;
      }
      if (data.status !== 200) {
        return;
      }
      const r: string[] = await data.json();
      setUsers(r.sort());
    }

    getUsers();
    getDesigns();

  }, []);

  return <div className={stylesGeneric["page"]}>
    <Background/>
    <div className={stylesGeneric["settings-button-row"]}>
      <div className={stylesGeneric["settings-button"]}>
        <HomeButton/>
      </div>
      <div className={stylesGeneric["settings-button"]}>
        <input
          type="file"
          className={styles["import-hide"]}
          id={`file-upload`}
          onChange={(e) => {
            handleUpload(e)
          }}
        />
        <button
          className={styles['assign-button']}
        >
          <label className={styles["import-button"]} htmlFor={`file-upload`}>
            <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faUpload}/>
          </label>
        </button>
      </div>
    </div>
    <div className={stylesGeneric["content-wrapper"]}>
      <div className={stylesGeneric["page-wrapper"]}>
        <div className={styles["input-box"]}>
          <div className={styles["input-row"]}>
            <div className={styles["input-one"]}>
              <input className={styles["input-serials"]} value={targetSerials} placeholder={"Target serials..."} onChange={(e) => setTargetSerials(e.target.value)}/>
            </div>
          </div>

          <div className={styles["input-row"]}>
            <div className={styles["input-two"]}>
              <Dropdown
                currentVal={targetUser === "" ? "Target user..." : targetUser}
                valueList={users}
                onChange={(val: string) => {setTargetUser(val)}}
              />
              <div className={styles["spacer"]}/>
              <Dropdown
                currentVal={targetDesign === "" ? "Target design..." : targetDesign}
                valueList={designs}
                onChange={(val: string) => {setTargetDesign(val)}}
              />
            </div>
            <input className={styles["button"]} value={"Send"} type={'button'} onClick={(e) => sendSerials()}/>
          </div>
        </div>
      </div>
    </div>
    <LoadingOverlay loading={loading}/>
  </div>
}
